var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('button',{staticClass:"btn px-3 py-2 ml-2 d-flex justify-content-center align-items-center shopify-btn",on:{"click":function($event){return _vm.openWixProductModal()}}},[_vm._v(" import from "),_c('i',{staticClass:"fab fa-wix ml-2",staticStyle:{"font-size":"20px"}})]),_c('ModalVue',{ref:"Modal",attrs:{"id":"copyWixProducts","title":"Import from Wix","size":"lg"}},[_c('div',{staticClass:"my-2",class:!_vm.isLoadingSync ? 'd-none' : 'd-block'},[_c('ProgressbarVue',{ref:"progressbar"}),_c('span',{staticClass:"text-success text-center",staticStyle:{"font-size":"12px","text-align":"center"}},[_vm._v(" Collections syncing soon, continue tasks uninterrupted. ")])],1),_c('FilterBox',{attrs:{"search":true,"placeholder":"Search Collections..."},on:{"input":function($event){return _vm.searchProductQueryHandle($event)}}}),_c('div',{staticClass:"loader px-2"},[_c('div',{staticClass:"d-flex py-2",class:[
            _vm.isLoadingWixProducts
              ? 'justify-content-between'
              : 'justify-content-end',
          ]},[(_vm.isLoadingWixProducts)?_c('div',{staticClass:"d-flex align-items-center"},[_c('b-spinner',{staticStyle:{"color":"#4d1b7e"},attrs:{"small":""}}),_c('span',{staticClass:"ml-2"},[_vm._v("Loading Collections")])],1):_vm._e(),(_vm.wixSelectedProducts.length)?_c('div',{staticClass:"float-right"},[_vm._v(" Selected Collections : "+_vm._s(_vm.wixSelectedProducts.length)+" ")]):_vm._e()])]),(_vm.isLoadingWixProductFirstTime)?_c('b-skeleton-table',{attrs:{"rows":5,"columns":3,"table-props":{ bordered: true, striped: true }}}):(_vm.wixProducts.length)?_c('div',{staticClass:"data-table"},[_c('div',{staticStyle:{"max-height":"300px","min-height":"300px","overflow":"auto"}},[_c('b-table',{staticClass:"leadTable",attrs:{"head-class":"text-center","stickyColumn":true,"hover":"","fields":_vm.wixProductsFields,"items":_vm.wixProducts},scopedSlots:_vm._u([{key:"head(checkbox)",fn:function(){return [_c('b-form-checkbox',{staticClass:"checkbox selectAll",attrs:{"size":"lg","disabled":_vm.isLoadingWixProducts ||
                  _vm.isLoadingSelectedWixProducts ||
                  _vm.isLoadingSync},on:{"change":function($event){return _vm.wixProductselectAllToggle($event)}},model:{value:(_vm.shopifySelectAllCheck),callback:function ($$v) {_vm.shopifySelectAllCheck=$$v},expression:"shopifySelectAllCheck"}})]},proxy:true},{key:"cell(checkbox)",fn:function(data){return [_c('b-form-checkbox',{staticClass:"checkbox",attrs:{"value":data.item.id,"disabled":_vm.isLoadingWixProducts ||
                  _vm.isLoadingSelectedWixProducts ||
                  _vm.isLoadingSync,"size":"lg"},model:{value:(_vm.wixSelectedProducts),callback:function ($$v) {_vm.wixSelectedProducts=$$v},expression:"wixSelectedProducts"}})]}},{key:"cell(image)",fn:function(data){return [_c('div',{staticClass:"d-flex gap align-items-center"},[_c('lazy-image',{key:data.item.image,staticClass:"circle-image mr-2",attrs:{"src":data.item.image
                         ? data.item.image
                         : 'https://images.quizell.com/website/default_start_page.png'}}),_c('div',{},[_vm._v(_vm._s(data.item.name))])],1)]}}])})],1)]):[_c('div',{staticClass:"d-flex justify-content-center align-items-center",staticStyle:{"height":"50vh"}},[_c('EmptyTable',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" No collection found ")]},proxy:true},{key:"description",fn:function(){return [_vm._v(" Time to fill your shelves or refine your search. Remember, your results will appear right here. ")]},proxy:true}])})],1)],_c('div',{staticClass:"d-flex justify-content-between"},[_c('ShopifyPagination',{attrs:{"hasNext":_vm.pageInfo.currentPage<_vm.pageInfo.totalPages,"hasPrevious":_vm.pageInfo.currentPage>1},on:{"onNext":() => {
              _vm.pageInfo.currentPage++
              _vm.getProductsFromWix();
            },"onPrevious":() => {
              _vm.pageInfo.currentPage--
              _vm.getProductsFromWix();
            }}}),_c('div',{staticClass:""},[(_vm.wixSelectedProducts.length)?_c('Button',{staticStyle:{"border-radius":"4px !important"},attrs:{"disabled":_vm.isLoadingSync ||
                _vm.isLoadingWixProducts ||
                _vm.isLoadingSelectedWixProducts,"isLoading":_vm.isLoadingSelectedWixProducts},on:{"click":function($event){return _vm.syncSelected()}}},[_vm._v("Sync Selected")]):_c('button',{staticClass:"btn d-flex px-3 justify-content-center align-items-center py-2 shopify-btn",attrs:{"disabled":_vm.isLoadingSync ||
                _vm.isLoadingWixProducts ||
                _vm.isLoadingSelectedWixProducts},on:{"click":function($event){return _vm.syncAllCollections()}}},[(_vm.isLoadingSync)?_c('div',{staticClass:"d-flex align-items-center"},[_c('b-spinner',{attrs:{"small":""}}),_c('span',{staticClass:"ml-1"},[_vm._v("Syncing...")])],1):_c('span',[_vm._v("Sync all collections")])])],1)],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }